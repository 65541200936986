<template>
  <gmap-map :center="center" :zoom="zoom" class="map-container">
    <gmap-info-window
      :options="infoOptions"
      :position="infoWindowPos"
      :opened="infoWinOpen"
      @closeclick="infoWinOpen = false"
    >
      <p>
        <strong style="font-size:14px;">{{ infoTitle }}</strong>
      </p>
      <p>
        {{ infoAddress }}
        {{ infoAddress2 }}
      </p>
      {{ infoPhone }}
      <br />
      {{ infoURL }}
    </gmap-info-window>
    <gmap-cluster>
      <gmap-marker
        v-for="(m, index) in markers"
        :key="`gm-${index}`"
        :position="m.position"
        :clickable="true"
        @click="toggleInfoWindow(m, index)"
      />
    </gmap-cluster>
  </gmap-map>
</template>

<script>
//center=m.position
export default {
  name: "GMapCustom",
  props: {
    dataset: {
      type: Array,
      default: () => []
    }
  },
  data: function() {
    return {
      center: { lat: 38.45, lng: -96.5333 },
      zoom: 5,
      markers: [],
      infoContent: "",
      infoTitle: "",
      infoURL: "",
      infoURLTXT: "",
      infoAddress: "",
      infoAddress2: "",
      infoPhone: "",
      infoWindowPos: null,
      infoWinOpen: false,
      currentMidx: null,
      //optional: offset infowindow so it visually sits nicely on top of our marker
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35
        }
      }
    };
  },

  mounted() {},
  methods: {
    changeZoom(zoom) {
      this.zoom = zoom;
    },
    changeCenter(lat, lng) {
      lat = parseFloat(lat);
      lng = parseFloat(lng);

      this.center = {
        lat: lat,
        lng: lng
      };
    },
    toggleInfoWindow: function(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoTitle = marker.infoTitle;
      this.infoURL = marker.infoURL !== "" ? `${marker.infoURL}` : "";
      this.infoAddress = marker.infoAddress;
      this.infoAddress2 = marker.infoAddress2;
      this.infoPhone = marker.infoPhone;

      //check if its the same marker that was selected if yes toggle
      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //if different marker set infowindow to open and reset current marker index
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    }
  },

  watch: {
    dataset: async function(newVal) {
      //this.$parent.$refs.topProgress.start();
      let _self = this;
      _self.markers = [];

      newVal.forEach(item => {
        _self.markers.push({
          position: { lat: parseFloat(item.lat), lng: parseFloat(item.lng) },
          infoTitle: item.accountname,
          infoURL: encodeURI(item.website),
          infoAddress: item.street,
          infoAddress2: `${item.city}, ${item.state}`,
          infoPhone: `${item.phone}`
        });
      });

      // this.$parent.$refs.topProgress.done();
    }
  }
};
</script>

<style>
.map-container {
  height: 500px;
}
</style>
