var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('gmap-map', {
    staticClass: "map-container",
    attrs: {
      "center": _vm.center,
      "zoom": _vm.zoom
    }
  }, [_c('gmap-info-window', {
    attrs: {
      "options": _vm.infoOptions,
      "position": _vm.infoWindowPos,
      "opened": _vm.infoWinOpen
    },
    on: {
      "closeclick": function closeclick($event) {
        _vm.infoWinOpen = false;
      }
    }
  }, [_c('p', [_c('strong', {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s(_vm.infoTitle))])]), _c('p', [_vm._v(" " + _vm._s(_vm.infoAddress) + " " + _vm._s(_vm.infoAddress2) + " ")]), _vm._v(" " + _vm._s(_vm.infoPhone) + " "), _c('br'), _vm._v(" " + _vm._s(_vm.infoURL) + " ")]), _c('gmap-cluster', _vm._l(_vm.markers, function (m, index) {
    return _c('gmap-marker', {
      key: "gm-".concat(index),
      attrs: {
        "position": m.position,
        "clickable": true
      },
      on: {
        "click": function click($event) {
          return _vm.toggleInfoWindow(m, index);
        }
      }
    });
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }