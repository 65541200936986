var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-card-body', [_vm.isLoading ? _c('b-row', [_c('b-col')], 1) : _vm._e(), _c('hr'), _c('b-row', {
    staticClass: "p-4"
  }, [_c('b-col', {
    attrs: {
      "lg": "1"
    }
  }, [_vm._v(" Last invoice ")]), _c('b-col', {
    attrs: {
      "lg": "11"
    }
  }, [_c('vue-slider', {
    attrs: {
      "marks": _vm.marks,
      "included": true
    },
    model: {
      value: _vm.sliderValue,
      callback: function callback($$v) {
        _vm.sliderValue = $$v;
      },
      expression: "sliderValue"
    }
  })], 1)], 1), _c('hr'), _c('b-row', {
    staticClass: "pt-3"
  }, [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "store-locator",
      "show-labels": false,
      "loaded": _vm.filteringPanel.loaded,
      "load-dictionaries": _vm.loadDictionaries,
      "districts": _vm.filteringPanel.districts,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "dict-loaded": _vm.onFilteringPanelLoad,
      "async-search": _vm.onAsyncSearch,
      "search": _vm.getData,
      "change": _vm.filterData,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "p-2"
  }, [_c('b-col', {
    attrs: {
      "lg": "7",
      "sm": "12"
    }
  }, [_c('gmap-custom', {
    ref: "gmapCustom",
    attrs: {
      "dataset": _vm.dataset.filtered
    }
  })], 1), _c('b-col', {
    attrs: {
      "lg": "3",
      "sm": "6"
    }
  }, [_c('b-alert', {
    attrs: {
      "show": ""
    }
  }, [_vm._v(" Accounts: " + _vm._s(_vm.dataset.accounts.length) + " ")]), _c('div', {
    staticClass: "accounts-container"
  }, _vm._l(_vm.dataset.accounts, function (item, index) {
    return _c('b-list-group', {
      key: "ac-".concat(index)
    }, [_c('b-list-group-item', {
      staticClass: "d-flex justify-content-between align-items-center",
      on: {
        "click": function click($event) {
          return _vm.onAccountClick(item);
        }
      }
    }, [_vm._v(" " + _vm._s(item.accountname) + " ")])], 1);
  }), 1)], 1), _c('b-col', {
    attrs: {
      "lg": "2",
      "sm": "6"
    }
  }, [_c('b-alert', {
    attrs: {
      "show": ""
    }
  }, [_vm._v(" Products: " + _vm._s(_vm.dataset.products.length) + " ")]), _c('div', {
    staticClass: "products-container"
  }, _vm._l(_vm.dataset.products, function (item, index) {
    return _c('b-list-group', {
      key: "pc-".concat(index)
    }, [_c('b-list-group-item', {
      staticClass: "d-flex justify-content-between align-items-center"
    }, [_vm._v(" " + _vm._s(item.name) + " "), _c('b-badge', {
      attrs: {
        "variant": "primary",
        "pill": ""
      }
    }, [_vm._v(" " + _vm._s(item.count) + " ")])], 1)], 1);
  }), 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }