<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row v-if="isLoading">
          <b-col />
        </b-row>

        <hr />
        <b-row class="p-4">
          <b-col lg="1"> Last invoice </b-col>
          <b-col lg="11">
            <vue-slider v-model="sliderValue" :marks="marks" :included="true" />
          </b-col>
        </b-row>
        <hr />
        <b-row class="pt-3">
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="store-locator"
              :show-labels="false"
              :loaded="filteringPanel.loaded"
              :load-dictionaries="loadDictionaries"
              :districts="filteringPanel.districts"
              :filters="filteringPanel.filters"
              @dict-loaded="onFilteringPanelLoad"
              @async-search="onAsyncSearch"
              @search="getData"
              @change="filterData"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <b-row class="p-2">
          <b-col lg="7" sm="12">
            <gmap-custom :dataset="dataset.filtered" ref="gmapCustom" />
          </b-col>
          <b-col lg="3" sm="6">
            <b-alert show> Accounts: {{ dataset.accounts.length }} </b-alert>

            <div class="accounts-container">
              <b-list-group
                v-for="(item, index) in dataset.accounts"
                :key="`ac-${index}`"
              >
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                  @click="onAccountClick(item)"
                >
                  {{ item.accountname }}
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
          <b-col lg="2" sm="6">
            <b-alert show> Products: {{ dataset.products.length }} </b-alert>

            <div class="products-container">
              <b-list-group
                v-for="(item, index) in dataset.products"
                :key="`pc-${index}`"
              >
                <b-list-group-item
                  class="d-flex justify-content-between align-items-center"
                >
                  {{ item.name }}
                  <b-badge variant="primary" pill>
                    {{ item.count }}
                  </b-badge>
                </b-list-group-item>
              </b-list-group>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import VueSlider from "vue-slider-component";

import GMapCustom from "@/components/GMapCustom";
import { isMobile } from "mobile-device-detect";

export default {
  name: "StoreLocator",
  components: {
    "gmap-custom": GMapCustom,
    "vue-slider": VueSlider
  },
  data: function() {
    return {
      isLoading: false,
      dataset: {
        full: [],
        filtered: [],
        products: [],
        accounts: []
      },

      filteringPanel: {
        loaded: false,
        districts: [],
        selected: {},
        filters: [
          {
            type: "select",
            dataType: "string",
            tooltip: "Country name",
            title: "Country",
            name: "country",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "State name",
            title: "State",
            name: "state",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "City name",
            title: "City",
            name: "city",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "Product",
            title: "Product",
            name: "product",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "Account premises",
            title: "Account premise",
            name: "category",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true
          },
          {
            type: "select",
            dataType: "string",
            tooltip: "Account name",
            title: "Account Name",
            name: "account",
            trackby: "id",
            label: "label",
            options: [],
            selected: {},
            multiple: true,
            async: true,
            loading: false,
            startsWith: false
          }
          /*
          {
            type: "daterange",
            dataType: "datetime",
            tooltip: "Activity period",
            title: "Activity date",
            name: "period"
          }
          */
        ]
      },
      sliderValue: 0,
      marks: {
        0: {
          period: 3 /*months*/,
          label: isMobile ? "3M" : "3 months ago",
          style: {
            width: "20px",
            height: "20px",
            display: "block",
            transform: "translate(-8px, -8px)",
            cursor: "pointer"
          },
          labelStyle: {
            color: "#fff",
            "background-color": "#007bff",
            left: "1em",
            "margin-top": "1em",
            "padding-right": ".6em",
            "padding-left": ".6em",
            "border-radius": "10rem"
          }
        },

        9: {
          period: 6,
          label: isMobile ? "6M" : "6 months ago",
          style: {
            width: "20px",
            height: "20px",
            display: "block",
            transform: "translate(-8px, -8px)",
            cursor: "pointer"
          },
          labelStyle: {
            color: "#fff",
            "background-color": "#007bff",
            "margin-top": "1em",
            "padding-right": ".6em",
            "padding-left": ".6em",
            "border-radius": "10rem"
          }
        },

        18: {
          period: 12,
          label: isMobile ? "1Y" : "1 year ago",
          style: {
            width: "20px",
            height: "20px",
            display: "block",
            transform: "translate(-8px, -8px)",
            cursor: "pointer"
          },
          labelStyle: {
            color: "#fff",
            "background-color": "#007bff",
            "margin-top": "1em",
            "padding-right": ".6em",
            "padding-left": ".6em",
            "border-radius": "10rem"
          }
        },

        37: {
          period: 24,
          label: isMobile ? "2Y" : "2 years ago",
          style: {
            width: "20px",
            height: "20px",
            display: "block",
            transform: "translate(-8px, -8px)",
            cursor: "pointer"
          },
          labelStyle: {
            color: "#fff",
            "background-color": "#007bff",
            "margin-top": "1em",
            "padding-right": ".6em",
            "padding-left": ".6em",
            "border-radius": "10rem"
          }
        },

        56: {
          period: 36,
          label: isMobile ? "3Y" : "3 years ago",
          style: {
            width: "20px",
            height: "20px",
            display: "block",
            transform: "translate(-8px, -8px)",
            cursor: "pointer"
          },
          labelStyle: {
            color: "#fff",
            "background-color": "#007bff",
            "margin-top": "1em",
            "padding-right": ".6em",
            "padding-left": ".6em",
            "border-radius": "10rem"
          }
        },
        75: {
          period: 48,
          label: isMobile ? "4Y" : "4 years ago",
          style: {
            width: "20px",
            height: "20px",
            display: "block",
            transform: "translate(-8px, -8px)",
            cursor: "pointer"
          },
          labelStyle: {
            color: "#fff",
            "background-color": "#007bff",
            "margin-top": "1em",
            "padding-right": ".6em",
            "padding-left": ".6em",
            "border-radius": "10rem"
          }
        },
        100: {
          period: 999,
          label: this.$isMobile ? "All" : "All time",
          style: {
            width: "20px",
            height: "20px",
            display: "block",
            transform: "translate(-8px, -8px)",
            cursor: "pointer"
          },
          labelStyle: {
            color: "#fff",
            left: "-1em",
            "margin-top": "1em",
            "background-color": "#007bff",
            "padding-right": ".6em",
            "padding-left": ".6em",
            "border-radius": "10rem"
          }
        }
      }
    };
  },
  mounted() {
    this.$refs.filteringPanel.selected.interval = 90;
    this.getData();
  },
  methods: {
    onAsyncSearch(payload) {
      if (payload.filterName === "account") {
        let accountsFilter = this.filteringPanel.filters.find(
          f => f.name === "account"
        );

        accountsFilter.loading = true;

        this.$api
          .post("dictionaries/accounts", {
            query: payload.query,
            starts_with: payload.startsWith
          })
          .then(response => {
            accountsFilter.loading = false;

            accountsFilter.options = response.map(u => ({
              id: u.id,
              label: u.name
            }));
          });
      }
    },
    filterData: function(e) {
      this.filteringPanel.selected = e;
    },
    async loadDictionaries() {
      const districts = async () => {
        let response = await this.$api.post("dictionaries/countries/mapped", {
          ignore_restrictions: false
        });

        this.filteringPanel.districts = response;

        this.filteringPanel.filters.find(
          f => f.name === "country"
        ).options = this.$helpers.getDistinctArray(
          response,
          "country",
          "country",
          "id",
          "label"
        );
      };

      const products = async () => {
        let response = await this.$api.products.get();

        //this.products = [...new Set(response.map(item => item.product))];

        this.filteringPanel.filters.find(
          f => f.name === "product"
        ).options = this.$helpers.getDistinctArray(
          response,
          "id",
          "name",
          "id",
          "label"
        );
      };

      const categories = async () => {
        let response = await this.$api.categories.get();

        this.filteringPanel.filters.find(
          f => f.name === "category"
        ).options = this.$helpers.getDistinctArray(
          response,
          "category",
          "category",
          "id",
          "label"
        );

        // console.log("this.filteringPanel.filters", this.filteringPanel.filters);
      };

      Promise.all([districts(), products(), categories()]).then(
        () => (this.filteringPanel.loaded = true)
      );
    },
    onFilteringPanelStateChange() {
      //this.dataTable.visible = !this.$refs.filteringPanel.expressionBuilder.visible;
    },
    onFilteringPanelLoad() {
      this.getData();
    },
    onCustomFilterSelected() {
      this.getData();
    },
    getData: function() {
      this.isLoading = true;

      this.$refs.filteringPanel.loading = true;

      let filter = this.$refs.filteringPanel.selected;

      filter.countries = filter.country
        ? filter.country.map(k => ({ name: k.label }))
        : [];
      filter.states = filter.state ? filter.state.map(k => k.label) : [];
      filter.cities = filter.city ? filter.city.map(k => k.label) : [];
      filter.products = filter.product ? filter.product.map(k => k.label) : [];
      filter.categories = filter.category
        ? filter.category.map(k => k.label)
        : [];
      filter.accounts = filter.account ? filter.account.map(k => k.label) : [];

      filter.interval = this.$refs.filteringPanel.selected.interval;

      if (this.$refs.filteringPanel.panelState.isCustomFiltersBar) {
        filter.expression = JSON.stringify(
          this.$refs.filteringPanel.prepareExpressions(filter["custom-filter"])
        );
      }

      this.$api.data.StoreLocator.getData(filter).then(response => {
        console.log("response", response);
        this.dataset.full = response;

        this.dataset.filtered = response;

        this.updateAccountList(response);

        this.isLoading = false;
      });

      this.$api.data.StoreLocator.getSoldProducts(filter).then(response => {
        this.updateProductList(response);
      });
    },

    updateProductList: function(data) {
      this.dataset.products = [];

      data.forEach(p => {
        let product = this.dataset.products.find(n => n.name === p.productname);

        if (!product) {
          this.dataset.products.push({ name: p.productname, count: p.count });
        }
      });

      this.dataset.products = this.dataset.products.sort((a, b) => {
        return b.count - a.count;
      });
    },
    updateAccountList: function(data) {
      this.dataset.accounts = [];

      data.forEach(p => {
        if (!this.dataset.accounts.find(n => n.accountname === p.accountname)) {
          this.dataset.accounts.push({
            accountname: p.accountname,
            lat: p.lat,
            lng: p.lng
          });
        }
      });
    },
    onAccountClick: function(e) {
      this.$refs.gmapCustom.changeCenter(e.lat, e.lng);
      this.$refs.gmapCustom.changeZoom(20);
    }
  },
  watch: {
    "filteringPanel.selected.country": function(newVal) {
      if (!newVal) return;

      this.filteringPanel.filters.find(
        f => f.name === "state"
      ).options = this.$helpers.getDistinctArray(
        this.filteringPanel.districts.filter(s =>
          newVal.map(l => l.label).includes(s.country)
        ),
        "state",
        "state",
        "id",
        "label"
      );
    },
    "filteringPanel.selected.state": function(newVal) {
      if (!newVal) return;
      this.filteringPanel.filters.find(
        f => f.name === "city"
      ).options = this.$helpers.getDistinctArray(
        this.filteringPanel.districts.filter(s =>
          newVal.map(l => l.label).includes(s.state)
        ),
        "city",
        "city",
        "id",
        "label"
      );
    },
    sliderValue: function(newVal) {
      this.$refs.filteringPanel.selected.interval =
        this.marks[newVal].period * 30;
    }
  }
};
</script>

<style>
.products-container {
  height: 400px;
  overflow-y: scroll;
}

.accounts-container {
  height: 400px;
  overflow-y: scroll;
  cursor: pointer;
}
[v-cloak] {
  display: none;
}
</style>
